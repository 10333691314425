<template lang="pug">
  ui-flex
    ui-cell(:grow="0")
      a.footer-item(href="https://www.vodafone.de/impressum.html" target="_blank" rel="noopener noreferrer")
        | Impressum
    ui-cell(:grow="0")
      a.footer-item(rel="noopener noreferrer" :href="termsOfUsePdf" target="_blank")
        | Datenschutz
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { PUBLIC_PATH } from '@/env';

@Component
export default class Footer extends Vue {
  private termsOfUsePdf = `${PUBLIC_PATH || ''}files/terms.pdf`;
}
</script>
<style scoped>
.footer-item {
  text-decoration: none;
  color: grey;
  font-size: 1.2rem;
  font-weight: 600;
}
</style>
