@import '@/styles/artifacts.scss';











































.icon {
  height: 36px;
}
.label {
  font-size: fontSize(fs-200);
  margin: 0;
}
.value {
  font-size: fontSize(fs-500);
  margin-left: 20px;
  margin: 0;
}
.unit {
  margin-left: 5px;
  font-size: fontSize(fs-100);
}
