@import '@/styles/artifacts.scss';
















































































.last-month-comparison-consumption {
  .label {
    margin: 0;
  }

  .content {
    text-align: right;
  }

  .percentage-diff {
    font-size: fontSize(fs-500);
    font-weight: 600;
    margin: 7px auto;
    letter-spacing: 0.1rem;

    &.negative {
      color: colorVodafone(green);
    }

    &.positive {
      color: colorVodafone(vodafone-red);
    }
  }

  .no-data {
    font-size: 1.1rem;
  }
}
