@import '@/styles/artifacts.scss';























































































































.info-consumption {
  text-align: center;
}
.bold {
  font-weight: bold;
}
