@import '@/styles/artifacts.scss';













































































































































































































































.info {
  margin: 0;
}

.date-container {
  position: relative;
  margin: 10px 0;

  .month-picker ::v-deep .trigger {
    width: 400px;
  }
}

.info-icon {
  position: absolute;
  top: 0;
  left: 305px;
}
