@import '@/styles/artifacts.scss';



















































































































































































































































































.chart {
  width: 100%;
  height: 100%;
}
