@import '@/styles/artifacts.scss';




























































































































.widget {
  height: 160px;
}

.content {
  margin-top: 10px;
}

.info {
  margin: 10px 0 0 0;
}

.time {
  .label {
    font-size: fontSize(fs-200);
    font-weight: 500;
  }

  .value {
    font-size: fontSize(fs-200);
    font-weight: 600;
    margin-left: 5px;
  }
}
